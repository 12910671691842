// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import jsPDF from "jspdf";
import "./App.css";

const App = () => {
  const [date, setDate] = useState("");
  const [transportCompany, setTransportCompany] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [tableOneImages, setTableOneImages] = useState([]);
  const [tableTwoImages, setTableTwoImages] = useState([]);
  const [matricule, setMatricule] = useState(""); // State to hold the matricule value


  const transportCompanies = [
    "Primafrio",
    "Cap Med Logistics",
    "Tawarik Trans",
    "Spot Logistics",
    "Touayri Tran",
    "Distrilog",
    "FLD SERVICES",
  ];
  const locations = ["Rotterdam", "Lyon", "Cavaillon", "Verona"];
  const tableTwoTitles = [
    "LOADED",
    "CLOSED DOOR",
    "SECURITY SEAL 1",
    "SECURITY SEAL 2",
    "",
  ];

  const handleImageUpload = (e, table) => {
    const files = Array.from(e.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));

    if (table === "tableOne") {
      setTableOneImages((prev) => [...prev, ...imageUrls]);
    } else if (table === "tableTwo") {
      setTableTwoImages((prev) => [...prev, ...imageUrls]);
    }
  };

  const handleInputChange = (event) => {
    setMatricule(event.target.value); // Update the matricule state with the input value
  };

  const generatePDF = () => {
    if (tableOneImages.length === 0 && tableTwoImages.length === 0) {
      alert("Please upload images for Table One or Table Two!");
      return;
    }
  
    const doc = new jsPDF();
    const imageWidth = 90;
    const imageHeight = 60;
    let currentImageIndex = 0;
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    // Add a fixed cover page
    const addFixedPage = (doc, isLastPage = false) => {
      // Add logo at the top center
      const logoPath = `${process.env.PUBLIC_URL}/img2.png`;
      const imgWidth = 100; // Width in PDF
      const imgHeight = 50; // Height in PDF
      doc.addImage(logoPath, "PNG", 50, 150, imgWidth, imgHeight);
  
      doc.setFont("helvetica", "bold");
      doc.setFontSize(20);
      doc.setTextColor(0, 0, 255); // Blue color for the title
      doc.text(
        isLastPage
          ? "End of Loading Process Documentation"
          : "Loading Process Documentation",
        pageWidth / 2,
        80,
        { align: "center" }
      );
  
      doc.setFontSize(14);
      doc.setTextColor(0); // Black text
      doc.text(
        isLastPage
          ? "Thank you for reviewing this document."
          : "Generated by Fruits For You",
        pageWidth / 2,
        100,
        { align: "center" }
      );
  
      doc.setFont("helvetica", "italic");
      doc.setFontSize(12);
      doc.text(
        isLastPage
          ? "Safe loading ensured by Fruits For You."
          : "Confidential Document",
        pageWidth / 2,
        120,
        { align: "center" }
      );
  
      // Add footer (optional)
      doc.setFontSize(10);
      doc.text(
        "© Fruits For You SARL AU",
        pageWidth / 2,
        pageHeight - 10,
        { align: "center" }
      );
    };
  
    // Insert the fixed cover page as the first page
    addFixedPage(doc);
    doc.addPage(); // Move to the next page for content
  
    // Page 1: Header
    doc.setFillColor(169, 169, 169); // Gray header
    doc.rect(0, 0, pageWidth, 15, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("LOADING PROCESS DOCUMENTATION", 10, 10);
  
    // Fixed Pack-House Line
    doc.setTextColor(0); // Black text
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("PACK-HOUSE: FRUITS FOR YOU", 10, 30); // Fixed text
  
    // Export Details
    doc.text(`DATE: ${date || "Not Provided"}`, 10, 20);
    doc.text("EXPORTER: FRUITS FOR YOU SARL AU", 10, 40);
    doc.text(
      `TRANSPORT COMPANY: ${transportCompany.toUpperCase() || "Not Provided"}`,
      10,
      50
    );
    doc.text(`Matricule: ${matricule}`, 100, 50); // Matricule value entered by the user
    doc.text(
      `DESTINATION: ${
        toLocation ? `FROM MOROCCO TO ${toLocation.toUpperCase()}` : "Not Provided"
      }`,
      10,
      60
    );
  
    // Add the Description Header
    doc.setTextColor(0, 0, 255); // Blue text for description title
    doc.text("DESCRIPTION:", 105, 70, { align: "center" });
  
    // Description Text
    const description = `THIS DOCUMENT PROVIDES A VISUAL RECORD OF THE LOADING PROCESS OF PALLETS PROVIDED BY FRUITS FOR YOU ONTO A TRUCK OF STE ${transportCompany.toUpperCase()}. IT INCLUDES THE PALLET NUMBER, WEIGHT, AND DIMENSIONS. THE DOCUMENT ALSO INCLUDES A QUALITY CHECK OF THE FRUITS, WHICH IS DONE BY THE PACK-HOUSE TEAM. THE DOCUMENT IS CREATED USING A NODE.JS APPLICATION THAT GENERATES A PDF FILE.WITH TEMPERATURE DATA AND QUALITY CHECKS.`;
  
    // Handle Description Text
    doc.setTextColor(0, 0, 0); // Black text for the content
    const marginX = 10; // Left margin (optional, for more control over text width)
    const maxWidth = 200 - marginX * 2; // Maximum width for wrapping text (adjust for page size)
    const lines = doc.splitTextToSize(description, maxWidth); // Split text into lines based on maxWidth
    let currentY = 83; // Position below the "DESCRIPTION" header
  
    // Add Description Text Line by Line with Center Alignment
    lines.forEach((line) => {
      const textWidth = doc.getTextWidth(line); // Get width of the current line of text
      const centerX = (200 - textWidth) / 2; // Calculate center position for the text on the page
      doc.text(line, centerX, currentY); // Render text centered
      currentY += 9; // Add vertical spacing for each line
    });
  
    // Table One Images
    doc.setTextColor(128, 0, 128); // Purple titles for table one images
    for (let i = 0; i < 4 && i < tableOneImages.length; i++) {
      const x = i % 2 === 0 ? 10 : 110;
      const y = 150 + Math.floor(i / 2) * 70;
  
      doc.addImage(tableOneImages[i], "JPEG", x, y, imageWidth, imageHeight);
      doc.setDrawColor(0, 0, 0);
      doc.rect(x, y, imageWidth, imageHeight);
      doc.setFontSize(10);
      doc.text(`Image of Temperature Check: Pallet ${i + 1}`, x, y + imageHeight + 5);
  
      currentImageIndex++;
    }
  
    let remainingImages = tableOneImages.slice(currentImageIndex);
    while (remainingImages.length > 0) {
      doc.addPage();
      doc.setTextColor(128, 0, 128);
      for (let i = 0; i < 6 && i < remainingImages.length; i++) {
        const x = i % 2 === 0 ? 10 : 110;
        const y = 20 + Math.floor(i / 2) * 70;
  
        doc.addImage(remainingImages[i], "JPEG", x, y, imageWidth, imageHeight);
        doc.setDrawColor(0, 0, 0);
        doc.rect(x, y, imageWidth, imageHeight);
        doc.setFontSize(10);
        doc.text(
          `Image of Temperature Check: Pallet ${currentImageIndex + i + 1}`,
          x,
          y + imageHeight + 5
        );
      }
      currentImageIndex += 6;
      remainingImages = remainingImages.slice(6);
    }
  
    // Table Two Images
    if (tableTwoImages.length > 0) {
      doc.addPage();
      doc.setTextColor(0, 0, 255);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(" SECURITY & CLOSURE", 75, 10);
  
      tableTwoImages.forEach((image, index) => {
        const x = index % 2 === 0 ? 10 : 110;
        const y = 30 + Math.floor(index / 2) * 70;
        doc.addImage(image, "JPEG", x, y, imageWidth, imageHeight);
        doc.setDrawColor(0, 0, 0);
        doc.rect(x, y, imageWidth, imageHeight);
        doc.setFontSize(10);
        doc.text(tableTwoTitles[index] || "No Title", x, y + imageHeight + 5);
      });
    }
  
 // Add a new page
doc.addPage();

// Set general font settings for the title and content
doc.setFont("helvetica", "bold");
doc.setFontSize(16);
doc.setTextColor(0, 0, 255); // Blue color for the title

// Title: "Avocado Transportation Procedures"
const title = "Avocado Transportation Procedures";
const titleWidth = doc.getTextWidth(title);
const titleX = (doc.internal.pageSize.width - titleWidth) / 2; // Center the title horizontally
doc.text(title, titleX, 20); // Positioning title

// Reset font settings for content
doc.setFontSize(12);
doc.setFont("helvetica", "normal");
doc.setTextColor(0, 0, 0); // Black color for content

let yPosition = 30; // Starting Y position for content

// Text content with colors and formatting
const textContent = [
  { text: "1. Pre-Loading Inspection:", color: "green" },
  { text: "- Before loading the avocados onto the truck, conduct a thorough visual inspection to ensure that the avocados meet the required quality standards.", color: "black" },
  
  { text: "2. Temperature Monitoring:", color: "green" },
  { text: "- The truck's refrigeration unit, commonly known as the 'Reefer Unit,' must be set to the precise temperature range required for avocado transportation. Use a reliable data logger to monitor and record temperature throughout the journey.", color: "black" },
  
  { text: "3. Security Seals:", color: "green" },
  { text: "- Secure the doors of the truck with tamper-evident security seals to prevent unauthorized access.", color: "black" },
  
  { text: "4. Driver Training:", color: "green" },
  { text: "- Ensure that the driver is trained to handle potential temperature-related issues and knows the procedure for notifying responsible personnel.", color: "black" },
  
  { text: "5. Emergency Protocol:", color: "green" },
  { text: "- In the event of temperature fluctuations or quality concerns upon arrival, follow these steps:\n1) Driver immediately contacts responsible personnel.\n2) An insurance expert is engaged to assess the situation.\n3) The truck is secured, and the avocados are unloaded under supervision.\n4) All relevant parties are informed in real-time to ensure a coordinated response.", color: "black" }
];

// Add the text content to the page with styling
textContent.forEach(line => {
  // Section header style (green color for headers)
  if (line.color === "green") {
    doc.setFontSize(14); // Bigger size for headers
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 128, 0); // Green color
  } else {
    // Regular content style (black color for content)
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black color
  }

  // Split long text into lines if necessary
  const maxWidth = doc.internal.pageSize.width - 20; // Set a margin for text
  const lines = doc.splitTextToSize(line.text, maxWidth);

  // Add the text lines to the PDF
  lines.forEach(lineText => {
    doc.text(lineText, 10, yPosition);
    yPosition += 10; // Increment Y position for the next line
  });

  // Add space after each section
  yPosition += 5;
});

// Optional: Add a footer or additional content
doc.setFontSize(10);
doc.setFont("helvetica", "normal");
doc.setTextColor(128, 128, 128); // Gray for footer text
doc.addPage();
    
    // Final page (Last Page)
    addFixedPage(doc, true); // Pass true to indicate this is the final page
    
    // Save the document
    doc.save("avocado_loading_report.pdf");
  };
  
  

  return (
    <div className="form-container">
      <header className="header">
        <h1>Generate PDF Report</h1>
      </header>

      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label><strong>Date:</strong></label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="input-field"
          />
        </div>

        <div className="form-group">
          <label><strong>Transport Company:</strong></label>
          <select
            value={transportCompany}
            onChange={(e) => setTransportCompany(e.target.value)}
            className="input-field"
          >
            <option value="">Select Transport Company</option>
            {transportCompanies.map((company) => (
              <option key={company} value={company}>
                {company}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label><strong>Matricule (Text input):</strong></label>
          <input
            type="text"
            value={matricule}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>

        <div className="form-group">
          <label><strong>To Location:</strong></label>
          <select
            value={toLocation}
            onChange={(e) => setToLocation(e.target.value)}
            className="input-field"
          >
            <option value="">Select Destination</option>
            {locations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label><strong>Table One Images:</strong></label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => handleImageUpload(e, 'tableOne')}
            className="file-input"
          />
        </div>

        <div className="form-group">
          <label><strong>Table Two Images:</strong></label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => handleImageUpload(e, 'tableTwo')}
            className="file-input"
          />
        </div>

        <button
          onClick={generatePDF}
          className="submit-button"
        >
          Generate PDF
        </button>
      </form>

      <footer className="footer">
        <p>© 2024 FRUITS FOR YOU. All Rights Reserved.</p>
        <p>© Made by <a href="https://www.instagram.com/dino_ta7la?igsh=cnV2b3JycGIyYmVv&fbclid=PAZXh0bgNhZW0CMTEAAaZgwJ0k5K6RWqtzfEO9LG3ZxOJhyUROfUb7OHOpOED1Dg142I71C3pT3WE_aem_RJ2tr9F9Ae5Nt41UVv8Mrw">youness talha </a> </p>
      </footer>
    </div>
  );
}

export default App;
